<script>
import { useStore } from 'vuex'
import useProgram from '@/composables/program'
import { onMounted } from 'vue'
import uploadFile from '@/components/file/upload-file.vue'

export default {
  props: {
    program_schema: {
      type: Object,
      required: true,
    },
    dados: {
      type: Object,
      required: true,
    },
    registro_id: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore()
    const formType = store.state.programForm.formType
    const programa = {
      program_schema: props.program_schema,
      dados: props.dados,
      registro_id: props.registro_id
    }
    const {
      setProgramForm,
      addNewDetailTableForm,
      removeDetailTableForm,
      handleFormSubmit,
      sis_detail_table,
      baseFormModifier,
      loading,
      btnloading
    } = useProgram(formType, programa)

    onMounted(() => {
      setProgramForm()
    })

    return {
      formType,
      loading,
      btnloading,
      handleFormSubmit,
      baseFormModifier,
      sis_detail_table,
      addNewDetailTableForm,
      removeDetailTableForm,
    }
  },
  components: {
    uploadFile
  }
}
</script>

<template>
  <div class="row">
    <div class="card">
      <div class="my-2 mb-3">
        <template v-if="loading">
          <div class="text-center">
            <b-spinner variant="primary" />
          </div>
        </template>
        <div v-if="formType === 'creation'">
          <div class="card-header">
            <h5 class="card-title">
              Adicione Arquivos
            </h5>
          </div>
          <div class="card-body">
            <uploadFile />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>